import * as React from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
  Th,
  Select,
  Avatar,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import styles from "./InviteUsers.module.scss";
import { EmailPlusIcon } from "../../components/Icons/Icon";
import { Paginator } from "../../widgets/Paginator/Paginator";
import { IoIosSearch } from "react-icons/io";
import { Link } from "react-router-dom";



export const InviteUsers = () => {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [itemsPerPages, setItemsPerPage] = React.useState<number>(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchValue, setSearchValue] = React.useState('');

  const handlePageChange = (page: number) => {
    let pages: number = page + 1;
    setCurrentPage(pages);
  };
  const handleItemsPerPageChange = (event: any) => {
    setItemsPerPage(parseInt(event.target.value, 10));
  };
  const handleURLClick = React.useCallback(async () => {
    onOpen();
    // setCrawlDatLoading(data);
    // setCrawlDatLoading(data);
    // const resposne = await fetchKnowledgebaseCrawlDataDetails(knowledgebaseId, crawlDataId);
    // setCrauledDataDetail(resposne.data?.content);
  }, [onOpen]);


  const handleInputChange = (event: any) => {
    setSearchValue(event.target.value); // Update state with the input value
  };
  return (
    <Box
      w="100%"
      p="6"
      shadow="sm"
      h="100%"
      position="relative"
      overflow="auto"
    >
      <VStack w="100%" spacing="10">
        <Flex
          shrink={0}
          w="100%"
          direction="column"
          justifyContent="space-between"
        >
          <Flex width="100%" mb="8" justifyContent="space-between">
            <Box>
              <Heading fontSize="30px" color="#1A202C">Invite Users</Heading>
            </Box>
            <Box>
              <Link to="/app/inviteUsers/usersHistory">
                <Button color={"#6C757D"} backgroundColor={'white'} size='md' border="1px solid #6C757D" mr="12px" fontSize="16px" className={styles.btnhover}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1734_6017)">
                      <rect width="16" height="16" fill="white" fill-opacity="0.01" />
                      <g clip-path="url(#clip1_1734_6017)">
                        <path d="M8.51465 1.01802C8.34327 1.00537 8.17149 0.999034 7.99965 0.999024V-0.000976281C8.19618 -0.000880453 8.39265 0.00645786 8.58864 0.0210237L8.51465 1.01802ZM10.5186 1.46802C10.1981 1.34433 9.86887 1.24439 9.53365 1.16902L9.75265 0.193024C10.1356 0.279024 10.5126 0.393024 10.8786 0.535024L10.5186 1.46802ZM11.8886 2.17802C11.7457 2.08267 11.5992 1.99261 11.4496 1.90802L11.9426 1.03802C12.2846 1.23176 12.6118 1.45035 12.9216 1.69202L12.3066 2.48102C12.1711 2.37525 12.0316 2.27452 11.8886 2.17902V2.17802ZM13.7226 3.96802C13.5248 3.687 13.3066 3.42094 13.0696 3.17202L13.7936 2.48202C14.0636 2.76702 14.3136 3.07202 14.5406 3.39202L13.7226 3.96802ZM14.4666 5.32002C14.401 5.16148 14.3296 5.00538 14.2526 4.85202L15.1456 4.40202C15.3222 4.75311 15.4727 5.11677 15.5956 5.49002L14.6456 5.80302C14.5919 5.63989 14.5322 5.47878 14.4666 5.32002ZM14.9966 7.82702C14.9886 7.48336 14.9551 7.14077 14.8966 6.80202L15.8816 6.63202C15.9486 7.01802 15.9876 7.41002 15.9976 7.80202L14.9976 7.82702H14.9966ZM14.8656 9.36502C14.8986 9.19502 14.9256 9.02602 14.9466 8.85502L15.9396 8.97802C15.8916 9.36818 15.8147 9.75423 15.7096 10.133L14.7456 9.86602C14.7916 9.70102 14.8316 9.53402 14.8656 9.36502ZM13.9136 11.744C14.0976 11.454 14.2596 11.15 14.3996 10.836L15.3136 11.241C15.1536 11.601 14.9686 11.947 14.7586 12.279L13.9136 11.744ZM12.9496 12.949C13.0716 12.827 13.1886 12.701 13.2996 12.571L14.0576 13.224C13.9293 13.3729 13.7955 13.517 13.6566 13.656L12.9496 12.949Z" fill="#6C757D" />
                        <path d="M7.99965 0.999024C6.84853 0.999107 5.71519 1.28307 4.70002 1.82576C3.68486 2.36845 2.81919 3.15312 2.1797 4.11027C1.54021 5.06742 1.14664 6.1675 1.03384 7.31308C0.921039 8.45866 1.0925 9.61438 1.53302 10.6779C1.97355 11.7414 2.66955 12.6798 3.55937 13.4101C4.4492 14.1403 5.50538 14.6399 6.63438 14.8645C7.76338 15.0891 8.93034 15.0318 10.0319 14.6977C11.1335 14.3636 12.1356 13.7629 12.9496 12.949L13.6566 13.656C12.7264 14.5868 11.5809 15.2738 10.3217 15.656C9.06247 16.0383 7.72841 16.1041 6.43771 15.8475C5.147 15.591 3.9395 15.02 2.92219 14.1853C1.90489 13.3505 1.10918 12.2777 0.605579 11.0619C0.101978 9.84613 -0.0939689 8.5249 0.0350989 7.21529C0.164167 5.90568 0.614264 4.64812 1.34551 3.55404C2.07676 2.45996 3.06658 1.56313 4.22727 0.943026C5.38796 0.322919 6.68369 -0.00132529 7.99965 -0.000976281V0.999024Z" fill="#6C757D" />
                        <path d="M7.49965 2.99902C7.63225 2.99902 7.75943 3.0517 7.8532 3.14547C7.94697 3.23924 7.99965 3.36642 7.99965 3.49902V8.70902L11.2476 10.565C11.3594 10.6325 11.4404 10.741 11.4733 10.8673C11.5061 10.9937 11.4883 11.1279 11.4235 11.2412C11.3587 11.3546 11.2522 11.4381 11.1266 11.4739C11.0011 11.5098 10.8665 11.4951 10.7516 11.433L7.25165 9.43302C7.17512 9.38931 7.11151 9.32615 7.06725 9.24993C7.023 9.17371 6.99967 9.08716 6.99965 8.99902V3.49902C6.99965 3.36642 7.05232 3.23924 7.14609 3.14547C7.23986 3.0517 7.36704 2.99902 7.49965 2.99902Z" fill="#6C757D" />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1734_6017">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                      <clipPath id="clip1_1734_6017">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <Text as="span" ms="8px">Invite History
                  </Text>
                </Button>
              </Link>
              <Button color={"#6C757D"} backgroundColor={'white'} size='md' border="1px solid #6C757D" mr="12px" fontSize="16px" className={styles.btnhover}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1734_6048)">
                    <rect width="16" height="16" fill="white" fill-opacity="0.01" />
                    <g clip-path="url(#clip1_1734_6048)">
                      <path d="M3.99966 -0.000976562C3.46923 -0.000976562 2.96052 0.209737 2.58545 0.58481C2.21038 0.959883 1.99966 1.46859 1.99966 1.99902V3.13202L1.05866 3.63402C0.738745 3.80461 0.471212 4.05894 0.284678 4.36983C0.098145 4.68072 -0.00037194 5.03647 -0.000335683 5.39902V13.999C-0.000335683 14.5295 0.210378 15.0382 0.585451 15.4132C0.960523 15.7883 1.46923 15.999 1.99966 15.999H13.9997C14.5301 15.999 15.0388 15.7883 15.4139 15.4132C15.7889 15.0382 15.9997 14.5295 15.9997 13.999V5.39902C15.9997 5.03647 15.9012 4.68072 15.7147 4.36983C15.5281 4.05894 15.2606 3.80461 14.9407 3.63402L13.9997 3.13202V1.99902C13.9997 1.46859 13.7889 0.959883 13.4139 0.58481C13.0388 0.209737 12.5301 -0.000976562 11.9997 -0.000976562H3.99966ZM13.9997 4.26602L14.4697 4.51602C14.6298 4.60129 14.7637 4.72851 14.8571 4.88405C14.9504 5.0396 14.9997 5.21761 14.9997 5.39902V6.21602L13.9997 6.81602V4.26602ZM12.9997 7.41602L9.24966 9.66602L7.99966 8.91602L6.74966 9.66602L2.99966 7.41602V1.99902C2.99966 1.73381 3.10502 1.47945 3.29256 1.29192C3.48009 1.10438 3.73445 0.999023 3.99966 0.999023H11.9997C12.2649 0.999023 12.5192 1.10438 12.7068 1.29192C12.8943 1.47945 12.9997 1.73381 12.9997 1.99902V7.41602ZM1.99966 6.81602L0.999664 6.21602V5.39902C0.999785 5.21778 1.04916 5.03998 1.14251 4.88462C1.23587 4.72927 1.36969 4.60221 1.52966 4.51702L1.99966 4.26602V6.81602ZM14.9997 7.38202V13.116L10.2217 10.249L14.9997 7.38202ZM14.9647 14.262C14.907 14.4737 14.7812 14.6606 14.6068 14.7938C14.4324 14.9269 14.2191 14.9991 13.9997 14.999H1.99966C1.78011 14.999 1.56665 14.9267 1.39225 14.7934C1.21785 14.66 1.09219 14.4729 1.03466 14.261L7.99966 10.082L14.9647 14.262ZM0.999664 13.115V7.38202L5.77766 10.249L0.999664 13.116V13.115Z" fill="#6C757D" />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_1734_6048">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_1734_6048">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text as="span" ms="8px">Email Template
                </Text>
              </Button>
              <Button color={"white"} backgroundColor={'#7C2FF1'} size='md' className="btn-hover-pri" onClick={() => {
                handleURLClick()
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1734_5999)">
                    <rect width="16" height="16" fill="white" fill-opacity="0.01" />
                    <g clip-path="url(#clip1_1734_5999)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.585451 2.58481C0.960523 2.20974 1.46923 1.99902 1.99966 1.99902H13.9997C14.5301 1.99902 15.0388 2.20974 15.4139 2.58481C15.7889 2.95988 15.9997 3.46859 15.9997 3.99902V8.49902C15.9997 8.63163 15.947 8.75881 15.8532 8.85258C15.7594 8.94635 15.6323 8.99902 15.4997 8.99902C15.3671 8.99902 15.2399 8.94635 15.1461 8.85258C15.0523 8.75881 14.9997 8.63163 14.9997 8.49902V5.38202L7.99966 9.58202L6.67366 8.78702L1.03366 12.258C1.09068 12.4706 1.21625 12.6585 1.3909 12.7925C1.56556 12.9264 1.77954 12.9991 1.99966 12.999H7.49966C7.63227 12.999 7.75945 13.0517 7.85322 13.1455C7.94699 13.2392 7.99966 13.3664 7.99966 13.499C7.99966 13.6316 7.94699 13.7588 7.85322 13.8526C7.75945 13.9463 7.63227 13.999 7.49966 13.999H1.99966C1.47096 13.999 0.963761 13.7897 0.588975 13.4168C0.214188 13.0439 0.00230783 12.5377 -0.000335693 12.009V3.99902C-0.000335693 3.46859 0.210378 2.95988 0.585451 2.58481ZM0.999664 11.104L5.70766 8.20702L0.999664 5.38202V11.104ZM0.999664 3.99902V4.21602L7.99966 8.41602L14.9997 4.21602V3.99902C14.9997 3.73381 14.8943 3.47945 14.7068 3.29192C14.5192 3.10438 14.2649 2.99902 13.9997 2.99902H1.99966C1.73445 2.99902 1.48009 3.10438 1.29256 3.29192C1.10502 3.47945 0.999664 3.73381 0.999664 3.99902Z" fill="white" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9745 14.9739C15.6309 14.3175 15.9997 13.4273 15.9997 12.499C15.9997 11.5708 15.6309 10.6805 14.9745 10.0241C14.3182 9.36777 13.4279 8.99902 12.4997 8.99902C11.5714 8.99902 10.6812 9.36777 10.0248 10.0241C9.36841 10.6805 8.99966 11.5708 8.99966 12.499C8.99966 13.4273 9.36841 14.3175 10.0248 14.9739C10.6812 15.6303 11.5714 15.999 12.4997 15.999C13.4279 15.999 14.3182 15.6303 14.9745 14.9739ZM13.8229 10.7543C13.8879 10.7639 13.9503 10.7863 14.0067 10.82C14.063 10.8538 14.1122 10.8983 14.1513 10.951C14.1904 11.0038 14.2188 11.0637 14.2348 11.1274C14.2507 11.1911 14.254 11.2573 14.2444 11.3223C14.2348 11.3873 14.2124 11.4497 14.1787 11.506L12.8437 13.732C12.785 13.8298 12.7048 13.9129 12.6092 13.9751C12.5136 14.0372 12.405 14.0767 12.2918 14.0906C12.1786 14.1045 12.0638 14.0924 11.9559 14.0552C11.8481 14.018 11.7502 13.9567 11.6697 13.876L10.8957 13.103C10.8018 13.0091 10.749 12.8818 10.749 12.749C10.749 12.6162 10.8018 12.4889 10.8957 12.395C10.9896 12.3011 11.1169 12.2484 11.2497 12.2484C11.3824 12.2484 11.5098 12.3011 11.6037 12.395L12.1507 12.942L13.3207 10.992C13.3544 10.9357 13.3989 10.8865 13.4517 10.8474C13.5044 10.8083 13.5643 10.7799 13.628 10.7639C13.6917 10.7479 13.758 10.7447 13.8229 10.7543Z" fill="white" />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_1734_5999">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_1734_5999">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text as="span" ms="8px">
                  Send Invitations
                </Text>
              </Button>
            </Box>
          </Flex>

        </Flex>
      </VStack>
      <Box mt="0px">
        <Flex justifyContent="flex-end">
          <InputGroup
            width="400px"
            // border="1px solid #6C757D"
            borderRadius="4px"
            borderColor="#6C757D"
          >
            <InputLeftElement pointerEvents='none'>
              <IoIosSearch color="#6C757D" />
            </InputLeftElement>
            <Input type='text' placeholder='Search by URL or Email ID' color="#6C757D" value={searchValue}
              onChange={handleInputChange} />
          </InputGroup>
        </Flex>
      </Box>
      <Box mt="24px" pt={12} pb={1} backgroundColor="white" boxShadow="sm" borderRadius="8px" minW="100%" w="600px">
        <Text as="div">
          <TableContainer overflow="auto" w="100%">
            <Table variant="simple" >
              <Thead>
                <Tr className={styles.tableHd}>
                  <Th>#</Th>
                  <Th>Website URL</Th>
                  <Th>Admin’s Email ID</Th>
                  <Th>Chatbot URL</Th>
                  <Th>Email Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {/* {users.allUsersProfileData ? users.allUsersProfileData.map((user: any, index: number) => (
                  <Tr key={index} className={styles.tableTd}>
                    <Td>{index + 1}</Td>
                    <Td>
                      <Flex align="center">
                        <Avatar size="sm" src={user.avatarUrl} h="40px" w="40px" borderRadius="6px" />
                        <Text color="#1A202C" fontWeight="500" ml="12px" textTransform="capitalize">{user.name}</Text>
                      </Flex>
                    </Td>
                    <Td>{user.email} {user.isActive === 0 ? <Text as="span" ml="15px" fontSize="10px" className={styles.projectCount}>Deleted</Text> : null}</Td>
                    <Td>{user.project}</Td>
                    <Td>{user?.subscriptionData?.name}</Td>
                    <Td>
                      {user?.monthUsage?.count ? `${formatNumber(user.monthUsage.count)} / ${formatNumber(user?.subscriptionData?.maxTokens)}` : `0 / ${formatNumber(user?.subscriptionData?.maxTokens)}`}
                    </Td>
                    <Td><LastCrawledFormate date={user.createdAt} /></Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          minW='8'
                          minH='8'
                          h="8"
                          aria-label='Options'
                          icon={<FiMoreHorizontal />}
                          color="#212529"
                          variant='outline'
                          backgroundColor="#F8F9FA"
                        />
                        <MenuList>
                          <Link to={`/app/users/profile/${user._id}`}>
                            <MenuItem fontSize="14px" color="#1A202C" fontWeight="500">
                              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              
                              </svg>
                              <Text as="span" ml="12px">View Profile</Text>
                            </MenuItem>
                          </Link>

                          <MenuItem fontSize="14px" color="#1A202C" fontWeight="500" onClick={() => deleteUser(user._id)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        
                            </svg>
                            <Text as="span" ml="12px">Delete</Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                )) : null} */}
              </Tbody>
            </Table>
          </TableContainer>
        </Text>
      </Box>
      <Box>
        {/* <Pagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        /> */}
        <HStack w="100%" justifyContent="space-between" p={4}>
          <Flex alignItems="center">
            <Text mr="8px" fontSize="16px" fontWeight="400" color="#212529">Total
              {/* {users.totalCount} */}
              items</Text>
            {/* <Paginator onPageChange={handlePageChange} pageRangeDisplayed={5} pageCount={users.pages}
            /> */}
          </Flex>
          <Flex alignItems="center">
            <Select
              value={itemsPerPages}
              onChange={handleItemsPerPageChange}
              width="auto"
              color="#6C757D"
              mr="8px"
            >
              <option value={10}>10 / page</option>
              <option value={20}>20 / page</option>
              <option value={50}>50 / page</option>
            </Select>
          </Flex>
        </HStack>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody bg="#F7FAFC">
            <Tabs isFitted variant='enclosed'>
              <TabList mb='0px'>
                <Tab _focus={{ boxShadow: 'none', backgroundColor: "#FFF" }} fontSize="16px">Yellow Pages Search</Tab>
                <Tab _focus={{ boxShadow: 'none', backgroundColor: "#FFF" }} fontSize="16px">GMB Search</Tab>
              </TabList>
              <TabPanels border="1px solid rgb(222, 226, 230)" bg="#fff">
                <TabPanel>
                  <Box textAlign="center">
                    <Text as="h4" mt="8px" fontSize="18px" fontWeight="700" color="#1A202C">Yellow Pages Search</Text>
                    <Text as="p" mt="8px" fontSize="14px" fontWeight="400" color="#6C757D">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</Text>
                  </Box>
                  <hr style={{ border: "1px soild #E2E8F0", marginTop: "24px", marginBottom: "24px" }} />
                  <Box>
                    <Text as="p" fontSize="16px" fontWeight="400" color="#212529" mb="8px">Yellow Pages Search:</Text>
                    <Flex alignItems="center">
                      <Text as="span" marginRight="8px">
                        {/* Any label or text you want to display */}
                      </Text>
                      <InputGroup
                        width="400px"
                        borderRadius="4px"
                        borderColor="#6C757D"
                        marginRight="8px"
                      >
                        <Input
                          type="text"
                          placeholder="Search query"
                          color="#6C757D"
                          value={searchValue}
                          onChange={handleInputChange}
                          size="md"
                        />
                      </InputGroup>

                      <Select
                        value={itemsPerPages}
                        onChange={handleItemsPerPageChange}
                        width="auto"
                        color="#6C757D"
                      >
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                      </Select>
                    </Flex>
                  </Box>
                  <Box mt="40px">
                    <Flex justifyContent="flex-end">
                      <Button bg="#6C757D" color="#FFFFFF" mr={3} onClick={onClose} fontSize="16px" fontWeight="400" className={styles.cancaleBtn}>
                        Cancel
                      </Button>
                      <Button bg="#7C2FF1" color="#FFFFFF" mr={3} onClick={onClose} fontSize="16px" fontWeight="400" className="btn-hover-pri">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1778_8843)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.585451 2.58481C0.960523 2.20974 1.46923 1.99902 1.99966 1.99902H13.9997C14.5301 1.99902 15.0388 2.20974 15.4139 2.58481C15.7889 2.95988 15.9997 3.46859 15.9997 3.99902V8.49902C15.9997 8.63163 15.947 8.75881 15.8532 8.85258C15.7594 8.94635 15.6323 8.99902 15.4997 8.99902C15.3671 8.99902 15.2399 8.94635 15.1461 8.85258C15.0523 8.75881 14.9997 8.63163 14.9997 8.49902V5.38202L7.99966 9.58202L6.67366 8.78702L1.03366 12.258C1.09068 12.4706 1.21625 12.6585 1.3909 12.7925C1.56556 12.9264 1.77954 12.9991 1.99966 12.999H7.49966C7.63227 12.999 7.75945 13.0517 7.85322 13.1455C7.94699 13.2392 7.99966 13.3664 7.99966 13.499C7.99966 13.6316 7.94699 13.7588 7.85322 13.8526C7.75945 13.9463 7.63227 13.999 7.49966 13.999H1.99966C1.47096 13.999 0.963761 13.7897 0.588975 13.4168C0.214188 13.0439 0.00230783 12.5377 -0.000335693 12.009V3.99902C-0.000335693 3.46859 0.210378 2.95988 0.585451 2.58481ZM0.999664 11.104L5.70766 8.20702L0.999664 5.38202V11.104ZM0.999664 3.99902V4.21602L7.99966 8.41602L14.9997 4.21602V3.99902C14.9997 3.73381 14.8943 3.47945 14.7068 3.29192C14.5192 3.10438 14.2649 2.99902 13.9997 2.99902H1.99966C1.73445 2.99902 1.48009 3.10438 1.29256 3.29192C1.10502 3.47945 0.999664 3.73381 0.999664 3.99902Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9745 14.9739C15.6309 14.3175 15.9997 13.4273 15.9997 12.499C15.9997 11.5708 15.6309 10.6805 14.9745 10.0241C14.3182 9.36777 13.4279 8.99902 12.4997 8.99902C11.5714 8.99902 10.6812 9.36777 10.0248 10.0241C9.36841 10.6805 8.99966 11.5708 8.99966 12.499C8.99966 13.4273 9.36841 14.3175 10.0248 14.9739C10.6812 15.6303 11.5714 15.999 12.4997 15.999C13.4279 15.999 14.3182 15.6303 14.9745 14.9739ZM13.8229 10.7543C13.8879 10.7639 13.9503 10.7863 14.0067 10.82C14.063 10.8538 14.1122 10.8983 14.1513 10.951C14.1904 11.0038 14.2188 11.0637 14.2348 11.1274C14.2507 11.1911 14.254 11.2573 14.2444 11.3223C14.2348 11.3873 14.2124 11.4497 14.1787 11.506L12.8437 13.732C12.785 13.8298 12.7048 13.9129 12.6092 13.9751C12.5136 14.0372 12.405 14.0767 12.2918 14.0906C12.1786 14.1045 12.0638 14.0924 11.9559 14.0552C11.8481 14.018 11.7502 13.9567 11.6697 13.876L10.8957 13.103C10.8018 13.0091 10.749 12.8818 10.749 12.749C10.749 12.6162 10.8018 12.4889 10.8957 12.395C10.9896 12.3011 11.1169 12.2484 11.2497 12.2484C11.3824 12.2484 11.5098 12.3011 11.6037 12.395L12.1507 12.942L13.3207 10.992C13.3544 10.9357 13.3989 10.8865 13.4517 10.8474C13.5044 10.8083 13.5643 10.7799 13.628 10.7639C13.6917 10.7479 13.758 10.7447 13.8229 10.7543Z" fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1778_8843">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <Text as="span" ms="8px">
                          Fetch Record
                        </Text>

                      </Button>
                    </Flex>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box textAlign="center">
                    <Text as="h4" mt="8px" fontSize="18px" fontWeight="700" color="#1A202C">GMB Search</Text>
                    <Text as="p" mt="8px" fontSize="14px" fontWeight="400" color="#6C757D">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took </Text>
                  </Box>
                  <hr style={{ border: "1px soild #E2E8F0", marginTop: "24px", marginBottom: "24px" }} />
                  <Box>
                    <Text as="p" fontSize="16px" fontWeight="400" color="#212529" mb="8px">Search GMB:</Text>
                    <Flex alignItems="center">
                      <Text as="span" marginRight="8px">
                        {/* Any label or text you want to display */}
                      </Text>
                      <InputGroup
                        width="400px"
                        borderRadius="4px"
                        borderColor="#6C757D"
                        marginRight="8px"
                      >
                        <Input
                          type="text"
                          placeholder="Search query"
                          color="#6C757D"
                          value={searchValue}
                          onChange={handleInputChange}
                          size="md"
                        />
                      </InputGroup>

                      <Select
                        value={itemsPerPages}
                        onChange={handleItemsPerPageChange}
                        width="auto"
                        color="#6C757D"
                      >
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                      </Select>
                    </Flex>
                  </Box>
                  <Box mt="40px">
                    <Flex justifyContent="flex-end">
                      <Button bg="#6C757D" color="#FFFFFF" mr={3} onClick={onClose} fontSize="16px" fontWeight="400" className={styles.cancaleBtn}>
                        Cancel
                      </Button>
                      <Button bg="#7C2FF1" color="#FFFFFF" mr={3} onClick={onClose} fontSize="16px" fontWeight="400" className="btn-hover-pri">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1778_8843)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.585451 2.58481C0.960523 2.20974 1.46923 1.99902 1.99966 1.99902H13.9997C14.5301 1.99902 15.0388 2.20974 15.4139 2.58481C15.7889 2.95988 15.9997 3.46859 15.9997 3.99902V8.49902C15.9997 8.63163 15.947 8.75881 15.8532 8.85258C15.7594 8.94635 15.6323 8.99902 15.4997 8.99902C15.3671 8.99902 15.2399 8.94635 15.1461 8.85258C15.0523 8.75881 14.9997 8.63163 14.9997 8.49902V5.38202L7.99966 9.58202L6.67366 8.78702L1.03366 12.258C1.09068 12.4706 1.21625 12.6585 1.3909 12.7925C1.56556 12.9264 1.77954 12.9991 1.99966 12.999H7.49966C7.63227 12.999 7.75945 13.0517 7.85322 13.1455C7.94699 13.2392 7.99966 13.3664 7.99966 13.499C7.99966 13.6316 7.94699 13.7588 7.85322 13.8526C7.75945 13.9463 7.63227 13.999 7.49966 13.999H1.99966C1.47096 13.999 0.963761 13.7897 0.588975 13.4168C0.214188 13.0439 0.00230783 12.5377 -0.000335693 12.009V3.99902C-0.000335693 3.46859 0.210378 2.95988 0.585451 2.58481ZM0.999664 11.104L5.70766 8.20702L0.999664 5.38202V11.104ZM0.999664 3.99902V4.21602L7.99966 8.41602L14.9997 4.21602V3.99902C14.9997 3.73381 14.8943 3.47945 14.7068 3.29192C14.5192 3.10438 14.2649 2.99902 13.9997 2.99902H1.99966C1.73445 2.99902 1.48009 3.10438 1.29256 3.29192C1.10502 3.47945 0.999664 3.73381 0.999664 3.99902Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9745 14.9739C15.6309 14.3175 15.9997 13.4273 15.9997 12.499C15.9997 11.5708 15.6309 10.6805 14.9745 10.0241C14.3182 9.36777 13.4279 8.99902 12.4997 8.99902C11.5714 8.99902 10.6812 9.36777 10.0248 10.0241C9.36841 10.6805 8.99966 11.5708 8.99966 12.499C8.99966 13.4273 9.36841 14.3175 10.0248 14.9739C10.6812 15.6303 11.5714 15.999 12.4997 15.999C13.4279 15.999 14.3182 15.6303 14.9745 14.9739ZM13.8229 10.7543C13.8879 10.7639 13.9503 10.7863 14.0067 10.82C14.063 10.8538 14.1122 10.8983 14.1513 10.951C14.1904 11.0038 14.2188 11.0637 14.2348 11.1274C14.2507 11.1911 14.254 11.2573 14.2444 11.3223C14.2348 11.3873 14.2124 11.4497 14.1787 11.506L12.8437 13.732C12.785 13.8298 12.7048 13.9129 12.6092 13.9751C12.5136 14.0372 12.405 14.0767 12.2918 14.0906C12.1786 14.1045 12.0638 14.0924 11.9559 14.0552C11.8481 14.018 11.7502 13.9567 11.6697 13.876L10.8957 13.103C10.8018 13.0091 10.749 12.8818 10.749 12.749C10.749 12.6162 10.8018 12.4889 10.8957 12.395C10.9896 12.3011 11.1169 12.2484 11.2497 12.2484C11.3824 12.2484 11.5098 12.3011 11.6037 12.395L12.1507 12.942L13.3207 10.992C13.3544 10.9357 13.3989 10.8865 13.4517 10.8474C13.5044 10.8083 13.5643 10.7799 13.628 10.7639C13.6917 10.7479 13.758 10.7447 13.8229 10.7543Z" fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1778_8843">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <Text as="span" ms="8px">
                          Fetch Record
                        </Text>

                      </Button>
                    </Flex>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
            {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button> */}


          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
