import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
  HStack,
  useToast,
  StackDivider,
  Button,
  ButtonGroup,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Alert,
  AlertIcon,
  ModalFooter,
} from "@chakra-ui/react";
import MessageIcon from "../../components/Icons/customPromptIcon/MessageIcon";
import { fetchKnowledgebaseDetails, updatePrompt, defaultBasePromptApi } from "../../services/knowledgebaseService";
import { useState, useEffect } from "react";
import { CurrentUser } from "../../services/appConfig";
const CustomPrompt = ({ chatbotId }: any) => {
  const [basePrompt, setBasePrompt] = useState("");
  const [customPromptData, setcustomPromptData] = useState("");
  const [updatePrompts, setUpdatePrompts] = useState(false);
  const toast = useToast();
  const [isOpens, setIsOpens] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const onCloses = () => {
    setIsOpens(false);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response: any = await fetchKnowledgebaseDetails(chatbotId);
        setBasePrompt(response.data.prompt)
      } catch (error) {
      }
    }
    fetchData();
  }, []);


  // Define fetchData outside the block
  async function fetchData() {
    try {
      const response: any = await fetchKnowledgebaseDetails(chatbotId);
      setBasePrompt(response.data.prompt);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (updatePrompts) {
      fetchData(); // Call the function
    }
  }, [updatePrompts]);

  const handleCustomPrompt = (e: any) => {
    setcustomPromptData(e.target.value);
  }

  const updatebase = async () => {
    try {
      const response: any = await updatePrompt(chatbotId, customPromptData);
      setcustomPromptData("")
      console.log("response:", response.data);
      setUpdatePrompts(true)
      toast({
        title: `Your custom prompt has successfully been added as the base prompt.`,
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Something went wrong please try again`,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsOpens(false);
    }
  }

  async function saveCustomPrompt() {
    try {
      if (customPromptData === "") {
        const userRole = CurrentUser.get();
        if (userRole.role == "admin") {
          toast({
            title: `You cannot change the specific settings of the admin's project.`,
            status: "error",
            isClosable: true,
          });
        } else {
          toast({
            title: `Please enter a custom prompt and then click on the 'Save Custom Prompt' button.`,
            status: "error",
            isClosable: true,
          });
        }

      }
      else {
        const userRole = CurrentUser.get();
        if (userRole.role == "admin") {
          toast({
            title: `You cannot change the specific settings of the admin's project.`,
            status: "error",
            isClosable: true,
          });
        } else {
          setIsOpens(true);
        }
      }

    } catch (error) {
      toast({
        title: `Something went wrong please try again`,
        status: "error",
        isClosable: true,
      });
    } finally {
      setUpdatePrompts(false)
    }
  }

  const updatedefault = async () => {
    try {
      const response: any = await defaultBasePromptApi(chatbotId);
      setUpdatePrompts(true)
      // window.location.reload();
      toast({
        title: `The prompt has successfully been reset to the default base prompt.`,
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Something went wrong please try again`,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsOpen(false);
    }
  }

  async function handleDefaultBasePrompt() {
    try {
      const userRole = CurrentUser.get();
      if (userRole.role == "admin") {
        toast({
          title: `You cannot change the specific settings of the admin's project.`,
          status: "error",
          isClosable: true,
        });
      } else {
        setIsOpen(true);
      }
    } catch (error) {
      toast({
        title: `Something went wrong please try again`,
        status: "error",
        isClosable: true,
      });
    } finally {
      setUpdatePrompts(false)

    }
  }


  return (
    <>
      <Box borderWidth='1px' borderRadius='lg' >
        <Stack
          divider={<StackDivider borderColor='gray.200' />}
          direction={["column", "row"]}
          spacing={1}
        >
          <Box p={3} width={["100%", "50%"]}>
            <Heading fontSize="20px" fontWeight="600" mt={2} mb={4}>Base Prompt</Heading>
            <Box color="#6C757D" fontSize="16px" fontWeight="400">
              <Textarea
                textAlign="justify"
                width="100%"
                height="500px"
                resize="none"
                value={basePrompt}
              >
              </Textarea>
            </Box>
          </Box>

          <Box p={3} width={["100%", "50%"]}>
            <Heading fontSize="20px" fontWeight="600" mt={2} mb={3}>Custom Prompt</Heading>
            <Text color="#6C757D" fontSize="16px" fontWeight="400px" mb={3}>In you want to change base prompt you can create you custom prompt</Text>
            <Box color="#6C757D" fontSize="16px" fontWeight="400">
              <Textarea
                textAlign="justify"
                width="100%"
                height="470px"
                resize="none"
                placeholder={`Please follow the following steps to change the default base prompt:\n1. Copy the default base prompt visible on the left-hand side.\n2. Paste it into this text box and modify the base prompt as per your custom requirement.\n3. Click on the 'Save Custom Prompt' button below.\n4. If you want to reset the prompt to the default prompt, please click on the 'Reset To Default Base Prompt' button below.\nPlease note:\nWe do not recommend you to update the custom prompt as it may result in inaccurate/incomplete responses being generated by the chatbot. In this case, please revert to the default base prompt by following the step number 4 above.`}
                id="customPromptId"
                onChange={handleCustomPrompt}
                value={customPromptData} // Bind state to value
              >
              </Textarea>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Stack direction='row' spacing={4} justifyContent="end" m={5} mb={1}>
        <Button leftIcon={<MessageIcon />} onClick={saveCustomPrompt} colorScheme='#7C2FF1' variant='solid' bg="#7C2FF1" fontSize="16px" fontWeight="400" _hover={{ bg: '#6120C5' }}>
          Save Custom Prompt
        </Button>
        <Button leftIcon={<MessageIcon />} onClick={handleDefaultBasePrompt} colorScheme='#6C757D' variant='solid' bg="#6C757D" fontSize="16px" fontWeight="400" _hover={{ bg: '#51585E' }}>
          Reset To Default Base Prompt
        </Button>
        <Modal isOpen={isOpens} onClose={onCloses} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Are you sure you want to update the default base prompt?
            </ModalHeader>
            <ModalCloseButton />
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={updatebase}>
                Yes
              </Button>
              <Button colorScheme='red' mr={3} onClick={onCloses}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Are you sure you want to reset the default base prompt?
            </ModalHeader>
            <ModalCloseButton />
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={updatedefault}>
                Yes
              </Button>
              <Button colorScheme='red' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );

}
export default CustomPrompt;