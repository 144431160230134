import React, { useEffect, useState } from 'react';
import {
  Box,
  Avatar,
  Text,
  Button,
  Input,
  IconButton,
  useColorModeValue,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Progress,
  VStack,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Link as ChakraLink,
  HStack,
  useToast,
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
} from '@chakra-ui/react';
import { MediaListItem } from '../../components/MediaListItem/MediaListItem';
import { deleteKnowledgebase, fetchKnowledgebases, generateEmbeddings, userProfilePreview, adminDeleteUser } from '../../services/knowledgebaseService';
import { Link, useHistory } from 'react-router-dom';
import { CurrentUser, User } from '../../services/appConfig';
import { useParams } from "react-router-dom";
import { formatNumber } from "../../utils/commonUtils";
import JoningOnFormate from '../../utils/JoningOnFormate';
import { getUserProfile } from '../../services/userServices';

export const MyProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { userId } = useParams<any>();
  const [profile, setProfile] = useState({
    name: 'Luisa Ashley',
    email: 'luisa.ashley.123@gmail.com',
    role: "admin",
    project: '6',
    joinedOn: '12-06-2024, 21:06',
    apiKey: '',
  });
  const toast = useToast();
  const [chatbotsList, setChatBotList] = React.useState<any>();
  const [user, setUser] = React.useState<User>(CurrentUser.get());
  const [userProfile, setUserProfile] = React.useState<any>();
  React.useEffect(() => {
    async function fetchData() {
      try {
        const userData = await getUserProfile();
        setUserProfile(userData.data);
      } catch (error) {

      } finally {
      }
    }
    fetchData();
  }, [])

  const [selectedImage, setSelectedImage] = useState<any>();

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      w="100%"
      p="6"
      shadow="sm"
      h="100%"
      overflow="auto"
    >
      <VStack w="100%" spacing="10" marginBottom="24px" marginTop="24px">
        <Flex w="100%" justifyContent="space-between">

          <Box>
            <Heading fontSize="30px" color="#1A202C">My Profile</Heading>
          </Box>
          <Flex>

            <Button color={"white"} backgroundColor={'#7C2FF1'} size='md' className="btn-hover-pri" onClick={handleEditToggle}>
              {isEditing ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.7066 4.49903L11.4998 2.29215C11.4072 2.19889 11.2971 2.12495 11.1757 2.07463C11.0544 2.02431 10.9243 1.99861 10.7929 1.99903H2.99976C2.73454 1.99903 2.48019 2.10439 2.29265 2.29192C2.10511 2.47946 1.99976 2.73381 1.99976 2.99903V12.999C1.99976 13.2642 2.10511 13.5186 2.29265 13.7061C2.48019 13.8937 2.73454 13.999 2.99976 13.999H12.9998C13.265 13.999 13.5193 13.8937 13.7069 13.7061C13.8944 13.5186 13.9998 13.2642 13.9998 12.999V5.2059C14.0002 5.07453 13.9745 4.94439 13.9242 4.82304C13.8738 4.70169 13.7999 4.59155 13.7066 4.49903ZM10.4998 12.999H5.49976V9.49903H10.4998V12.999ZM12.9998 12.999H11.4998V9.49903C11.4998 9.23381 11.3944 8.97946 11.2069 8.79192C11.0193 8.60439 10.765 8.49903 10.4998 8.49903H5.49976C5.23454 8.49903 4.98019 8.60439 4.79265 8.79192C4.60511 8.97946 4.49976 9.23381 4.49976 9.49903V12.999H2.99976V2.99903H10.7929L12.9998 5.2059V12.999ZM9.99976 4.49903C9.99976 4.63164 9.94708 4.75881 9.85331 4.85258C9.75954 4.94635 9.63236 4.99903 9.49976 4.99903H5.99976C5.86715 4.99903 5.73997 4.94635 5.6462 4.85258C5.55243 4.75881 5.49976 4.63164 5.49976 4.49903C5.49976 4.36642 5.55243 4.23924 5.6462 4.14548C5.73997 4.05171 5.86715 3.99903 5.99976 3.99903H9.49976C9.63236 3.99903 9.75954 4.05171 9.85331 4.14548C9.94708 4.23924 9.99976 4.36642 9.99976 4.49903Z" fill="white" />
              </svg>
                : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1492 0.131757C12.1957 0.0851938 12.2508 0.048251 12.3116 0.0230445C12.3723 -0.00216192 12.4375 -0.0151367 12.5032 -0.0151367C12.569 -0.0151367 12.6341 -0.00216192 12.6949 0.0230445C12.7556 0.048251 12.8108 0.0851938 12.8572 0.131757L15.8572 3.13176C15.9038 3.1782 15.9407 3.23338 15.9659 3.29412C15.9911 3.35487 16.0041 3.41999 16.0041 3.48576C16.0041 3.55152 15.9911 3.61665 15.9659 3.67739C15.9407 3.73814 15.9038 3.79331 15.8572 3.83976L5.85722 13.8398C5.80924 13.8874 5.75209 13.9248 5.68922 13.9498L0.689224 15.9498C0.59836 15.9861 0.49882 15.995 0.402944 15.9754C0.307067 15.9557 0.219071 15.9083 0.149865 15.8391C0.0806583 15.7699 0.0332848 15.6819 0.0136173 15.586C-0.00605013 15.4902 0.00285338 15.3906 0.039224 15.2998L2.03922 10.2998C2.06415 10.2369 2.10157 10.1797 2.14922 10.1318L12.1492 0.131757ZM11.2102 2.48576L13.5032 4.77876L14.7962 3.48576L12.5032 1.19276L11.2102 2.48576ZM12.7962 5.48576L10.5032 3.19276L4.00322 9.69276V9.98576H4.50322C4.63583 9.98576 4.76301 10.0384 4.85678 10.1322C4.95055 10.226 5.00322 10.3531 5.00322 10.4858V10.9858H5.50322C5.63583 10.9858 5.76301 11.0384 5.85678 11.1322C5.95055 11.226 6.00322 11.3531 6.00322 11.4858V11.9858H6.29622L12.7962 5.48576ZM3.03522 10.6608L2.92922 10.7668L1.40122 14.5878L5.22222 13.0598L5.32822 12.9538C5.23284 12.9181 5.15062 12.8542 5.09254 12.7706C5.03446 12.6869 5.0033 12.5876 5.00322 12.4858V11.9858H4.50322C4.37062 11.9858 4.24344 11.9331 4.14967 11.8393C4.0559 11.7455 4.00322 11.6184 4.00322 11.4858V10.9858H3.50322C3.4014 10.9857 3.30203 10.9545 3.2184 10.8964C3.13477 10.8384 3.07086 10.7561 3.03522 10.6608Z" fill="white" />
                </svg>}

              <Text as="span" marginLeft="8px">  {isEditing ? 'Save Changes' : 'Profile Edit'}</Text>
            </Button>
          </Flex>
        </Flex>
      </VStack>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p="6"
        boxShadow="md"
        bg={useColorModeValue('white', 'gray.800')}
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Box pr={8} mb={{ base: 4, md: 0 }} textAlign="center" flex="1">
          <Box mb="19px" display="flex"
            alignItems="center"
            justifyContent="center">

            <Image
              src={userProfile === undefined ? null : userProfile.avatarUrl}
              height="120px"
              width="120px"
              borderRadius="100px"
              cursor={isEditing ? 'pointer' : 'default'}
              onClick={() => {
                if (isEditing) {
                  document.getElementById('fileInput')?.click();
                }
              }}
            />
            {isEditing && (
              <>
                <IconButton
                  icon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1492 0.131757C12.1957 0.0851938 12.2508 0.048251 12.3116 0.0230445C12.3723 -0.00216192 12.4375 -0.0151367 12.5032 -0.0151367C12.569 -0.0151367 12.6341 -0.00216192 12.6949 0.0230445C12.7556 0.048251 12.8108 0.0851938 12.8572 0.131757L15.8572 3.13176C15.9038 3.1782 15.9407 3.23338 15.9659 3.29412C15.9911 3.35487 16.0041 3.41999 16.0041 3.48576C16.0041 3.55152 15.9911 3.61665 15.9659 3.67739C15.9407 3.73814 15.9038 3.79331 15.8572 3.83976L5.85722 13.8398C5.80924 13.8874 5.75209 13.9248 5.68922 13.9498L0.689224 15.9498C0.59836 15.9861 0.49882 15.995 0.402944 15.9754C0.307067 15.9557 0.219071 15.9083 0.149865 15.8391C0.0806583 15.7699 0.0332848 15.6819 0.0136173 15.586C-0.00605013 15.4902 0.00285338 15.3906 0.039224 15.2998L2.03922 10.2998C2.06415 10.2369 2.10157 10.1797 2.14922 10.1318L12.1492 0.131757ZM11.2102 2.48576L13.5032 4.77876L14.7962 3.48576L12.5032 1.19276L11.2102 2.48576ZM12.7962 5.48576L10.5032 3.19276L4.00322 9.69276V9.98576H4.50322C4.63583 9.98576 4.76301 10.0384 4.85678 10.1322C4.95055 10.226 5.00322 10.3531 5.00322 10.4858V10.9858H5.50322C5.63583 10.9858 5.76301 11.0384 5.85678 11.1322C5.95055 11.226 6.00322 11.3531 6.00322 11.4858V11.9858H6.29622L12.7962 5.48576ZM3.03522 10.6608L2.92922 10.7668L1.40122 14.5878L5.22222 13.0598L5.32822 12.9538C5.23284 12.9181 5.15062 12.8542 5.09254 12.7706C5.03446 12.6869 5.0033 12.5876 5.00322 12.4858V11.9858H4.50322C4.37062 11.9858 4.24344 11.9331 4.14967 11.8393C4.0559 11.7455 4.00322 11.6184 4.00322 11.4858V10.9858H3.50322C3.4014 10.9857 3.30203 10.9545 3.2184 10.8964C3.13477 10.8384 3.07086 10.7561 3.03522 10.6608Z" fill="white" />
                  </svg>}
                  aria-label="Edit Profile Picture"
                  position="absolute"
                  bottom="2"
                  right="2"
                  size="sm"
                  colorScheme="purple"
                  borderRadius="full"
                  onClick={() => {
                    if (isEditing) {
                      document.getElementById('fileInput')?.click();
                    }
                  }}
                />
                <Input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  display="none"
                  onChange={handleImageChange}
                />
              </>
            )}
          </Box>{console.log(userProfile)}
          <Text fontWeight="500" fontSize="20px" mb="10px" colorScheme="#1A202C">
            {userProfile === undefined ? null : userProfile.name}
          </Text>
          <Text fontSize="14px" color="#1A202C" mb="6px" fontWeight="500" colorScheme="#1A202C">
            {userProfile === undefined ? null : userProfile.subscriptionData.name}
          </Text>
          <Box>
            <Box>
              <Text as="span" fontWeight="bold">
                {userProfile === undefined ? null : user?.monthUsage?.count ? formatNumber(userProfile.monthUsage.count) : 0}</Text>/<span style={{ color: "#6C757D" }}>{userProfile === undefined ? null : userProfile.subscriptionData?.maxTokens ? formatNumber(userProfile.subscriptionData?.maxTokens) : 0}</span>

            </Box>

            <Box position="relative" display="inline-block" width="75%" marginTop="14px" height="14px">
              {userProfile?.monthUsage && userProfile?.subscriptionData?.maxTokens ? (
                (() => {
                  const usage = (userProfile.monthUsage.count * 100) / userProfile.subscriptionData.maxTokens;
                  return (
                    <>
                      <Progress value={usage} colorScheme="purple" borderRadius="2px" height="14px" />
                      <Box
                        position="absolute"
                        top="0"
                        left="0"
                        height="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="10px"
                        fontWeight="500"
                        color={usage >= 20 ? "#fff" : "#000"}
                        w={usage >= 20 ? `${usage}%` : `${60}%`}
                      >
                        {usage.toFixed(2)}%
                      </Box>
                    </>
                  );
                })()
              ) : (
                <>
                  <Progress value={0} colorScheme="purple" borderRadius="2px" />
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="10px"
                    fontWeight="500"
                    color={0 >= 20 ? "#fff" : "#000"}
                    w={0 >= 20 ? `${0}%` : `${60}%`}
                  >
                    {0}%
                  </Box></>
              )}
            </Box>


          </Box>
        </Box>
        <Box width="1px" bg="gray.200" mx={8} />

        <Box flex="1 1 50%">
          <Table variant="simple" size="sm">
            <Tbody className='profileEdit' style={{ border: "2px solid #E5E7EB" }}>
              <Tr>
                <Th>Name</Th>
                <Td>
                  {isEditing ? (
                    <Input
                      value={profile.name}
                      name="name"
                      onChange={handleInputChange}
                      placeholder="Name"
                    />
                  ) : (
                    <Text>
                      {userProfile === undefined ? null : userProfile.name}
                    </Text>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Th>Email</Th>
                <Td>
                  {isEditing ? (
                    <Input
                      value={profile.email}
                      name="email"
                      onChange={handleInputChange}
                      placeholder="Email"
                    />
                  ) : (
                    <Text>
                      {userProfile === undefined ? null : userProfile.email}
                    </Text>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Th>Role</Th>
                <Td>
                  {isEditing ? (
                    <Input
                      value={profile.role}
                      name="role"
                      onChange={handleInputChange}
                      placeholder="role"
                    />
                  ) : (
                    <Text>
                      {userProfile === undefined ? null : userProfile.role}
                    </Text>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Th>Project</Th>
                <Td>
                  {isEditing ? (
                    <Input
                      value={profile.project}
                      name="project"
                      onChange={handleInputChange}
                      placeholder="Project"
                    />
                  ) : (
                    <Text>
                      {/* {userProfile === undefined ? null : userProfile.userProjects.length} */}
                    </Text>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Th>Joined On</Th>
                <Td>
                  {isEditing ? (
                    <Input
                      value={profile.joinedOn}
                      name="joinedOn"
                      onChange={handleInputChange}
                      placeholder="Joined On"
                    />
                  ) : (
                    <Text>
                      {/* {userProfile === undefined ? null : <JoningOnFormate date={userProfile.createdAt} />} */}
                    </Text>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Th>OpenAI API Key</Th>
                <Td>
                  {isEditing ? (
                    <Input
                      value={profile.apiKey}
                      name="apiKey"
                      onChange={handleInputChange}
                      placeholder="Enter API Key"
                    />
                  ) : (
                    <Text>Enter API Key</Text>
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>

          {/* <Box mt={4} textAlign="right">
                    <Button
                      // leftIcon={<EditIcon />}
                      colorScheme="purple"
                      onClick={handleEditToggle}
                    >
                      {isEditing ? 'Save' : 'Profile Edit'}
                    </Button>
                  </Box> */}
        </Box>
      </Box>
    </Box >
  );
};
