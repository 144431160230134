import * as React from "react";

import {
	Box,
	Flex,
	useToast,
	Button,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,

	useDisclosure,
	FormHelperText,
} from "@chakra-ui/react";
import { FiPlus } from 'react-icons/fi';
import { addTrainingData } from "../../services/knowledgebaseService";
import MDEditor from '@uiw/react-md-editor';
import style from "./AddTrainingData.module.scss"
import { CurrentUser } from "../../services/appConfig";

export const AddTrainingData = ({
	initialAnswer,
	initialQuestion,
	onSubmit,
	knowledgeBaseId
}: any) => {

	const { isOpen, onOpen, onClose } = useDisclosure()
	const toast = useToast();

	const initialRef = React.useRef(null)
	const finalRef = React.useRef(null)

	const [answer, setAnswer] = React.useState<string>('');
	const [question, setQuestion] = React.useState<string>('');

	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);


	const handleAnswerChange = React.useCallback((value) => {
		setAnswer(value);
	}, [])
	const handleQuestionChange = React.useCallback((e) => {
		setQuestion(e.target.value)
	}, [])
	const handleSubmit = React.useCallback(async (e) => {
		const userRole = CurrentUser.get();
		if (userRole.role == "admin") {
			toast({
				title: `You cannot change the specific settings of the admin's project.`,
				status: "error",
				isClosable: true,
			});

		} else {
			try {
				setIsSubmitting(true)
				const data = ({
					q: question,
					a: answer
				});
				const response: any = await addTrainingData(knowledgeBaseId, data)
				setAnswer('');
				setQuestion('');
				onSubmit(response.data);
				onClose()
			} catch (error: any) {
				const errorData = error?.response?.data?.message
				toast({
					title: (errorData) || 'Oops! Something went wrong',
					status: "error",
					isClosable: true,
				});
			} finally {
				setIsSubmitting(false)
			}
		}
	}, [question, answer, knowledgeBaseId, onSubmit, onClose, toast])

	return (
		<Box>
			<Box>
				<Button leftIcon={<FiPlus />} onClick={onOpen} bg="#fff" color="#7C2FF1" border="1px solid #7C2FF1" className={style.addBtn}>Add data</Button>
			</Box>
			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
				size="5xl"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add new training data</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<FormControl>
							<FormLabel fontSize="16px" color="#212529">Title</FormLabel>
							<Input ref={initialRef} value={question}
								onChange={handleQuestionChange} placeholder='Question' />
						</FormControl>

						<FormControl mt={6}>
							<FormLabel fontSize="16px" color="#212529">Data</FormLabel>
							<Box data-color-mode="light">
								<MDEditor height={300} value={answer} preview="edit" onChange={handleAnswerChange} />
							</Box>
							<FormHelperText fontSize="smaller" color="gray.400">
								One of the best option to add training data is using the question and answer format. For example:
							</FormHelperText>
						</FormControl>
						<Flex mt="8" justifyContent="end">
							<Button onClick={onClose} mr={3}>Cancel</Button>
							<Button onClick={handleSubmit} colorScheme='blue' isLoading={isSubmitting}>
								Save
							</Button>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
};
